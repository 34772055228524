<template>
  <div class="welcome-package-preloader">
    <div class="welcome-package-preloader__steps"/>  
    <div class="welcome-package-preloader__cards">
      <div
        v-for="n in INITIAL_CARDS_COUNT"
        :key="n"
        class="welcome-package-preloader__card"
      />
    </div>
  </div>
</template>

  <script setup lang='ts'>

  const INITIAL_CARDS_COUNT = 4;

  </script>
  
  <style src="~/assets/styles/components/skeleton-preloader/welcome-package.scss" lang="scss" />
  
  